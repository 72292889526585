import { Injectable } from "@angular/core";
import { PermissionService } from "./permission.service";
import { Store } from "@ngrx/store";
import { AppState } from "@state";
import { TableSizePage } from "../../shared/interfaces/table-size-page";
import {
  SetTablePageSizeAction,
  SetTablePagesSizeAction,
} from "../../redux/actions/global.actions";
// import * as CryptoJS from 'crypto-js';
const { version: appVersion } = require("../../../../package.json");

@Injectable({
  providedIn: "root",
})
export class LocalStorService {
  constructor(
    private permission: PermissionService,
    private store: Store<AppState>,
  ) {}

  saveStartPage(page) {
    localStorage.setItem("startPage", page);
  }
  getStartPage() {
    let path: string = "/main/devices";

    if (localStorage.getItem("startPage")) {
      path = this.permission.checkStartPagePermission(
        localStorage.getItem("startPage"),
      );
    }

    this.saveStartPage(path);
    return path;
  }

  saveTablePageSize(size) {
    localStorage.setItem("tablePageSize", size);
    this.store.dispatch(new SetTablePageSizeAction(size));
  }

  getTablePageSize() {
    let size = 10;
    if (localStorage.getItem("tablePageSize")) {
      size = Number(localStorage.getItem("tablePageSize"));
    } else {
      this.saveTablePageSize(size);
    }
    this.store.dispatch(new SetTablePageSizeAction(size));
    return size;
  }

  saveUserName(name) {
    localStorage.setItem("userName", name);
  }

  getUserName() {
    return localStorage.getItem("userName");
  }

  saveTableSizePageFromUserName(size: number, nameTable: string) {
    if (localStorage.getItem("tablePageSizeUser")) {
      const tmpUsername = this.getUserName();
      const tmpArr: TableSizePage[] = JSON.parse(
        localStorage.getItem("tablePageSizeUser"),
      );

      let isExist = false;
      tmpArr.forEach((item) => {
        if (item.userName === tmpUsername) {
          isExist = true;
          item[nameTable] = size;
          if (nameTable !== "volumesOneDevice") {
            this.store.dispatch(new SetTablePagesSizeAction(item));
          }
        }
      });
      if (!isExist) {
        const tmpObj: TableSizePage = { userName: tmpUsername };
        tmpObj[nameTable] = size;
        tmpArr.push(tmpObj);
        if (nameTable !== "volumesOneDevice") {
          this.store.dispatch(new SetTablePagesSizeAction(tmpObj));
        }
      }
      localStorage.setItem("tablePageSizeUser", JSON.stringify(tmpArr));
    } else {
      const tmpObjSave: TableSizePage = { userName: this.getUserName() };
      tmpObjSave[nameTable] = size;

      const tmpJson = JSON.stringify([tmpObjSave]);
      localStorage.setItem("tablePageSizeUser", tmpJson);
      if (nameTable !== "volumesOneDevice") {
        this.store.dispatch(new SetTablePagesSizeAction(tmpObjSave));
      }
    }
  }

  getTableSizePageFromUserName() {
    const tmpUsername = this.getUserName();
    const tmpArr: TableSizePage[] = JSON.parse(
      localStorage.getItem("tablePageSizeUser"),
    );

    if (tmpArr !== null && tmpArr.length !== 0) {
      const tmp: TableSizePage[] = tmpArr.filter(
        (item) => item.userName === tmpUsername,
      );
      this.store.dispatch(new SetTablePagesSizeAction(tmp[0]));
    }
  }

  saveSettingColumnVisibleLS(tableId: string, columns: string[]) {
    const settingColumnVisibleObj = localStorage.getItem(
      "settingColumnVisible",
    );

    if (settingColumnVisibleObj) {
      const tmpSaveObj = JSON.parse(
        localStorage.getItem("settingColumnVisible"),
      );
      if (tmpSaveObj[this.getUserName()]) {
        tmpSaveObj[this.getUserName()][tableId] = columns;
        localStorage.setItem(
          "settingColumnVisible",
          JSON.stringify(tmpSaveObj),
        );
      } else {
        tmpSaveObj[this.getUserName()] = {};
        tmpSaveObj[this.getUserName()][tableId] = columns;
        localStorage.setItem(
          "settingColumnVisible",
          JSON.stringify(tmpSaveObj),
        );
      }
    } else {
      const tmpSaveObj = {};
      tmpSaveObj[this.getUserName()] = {};
      tmpSaveObj[this.getUserName()][tableId] = columns;

      localStorage.setItem("settingColumnVisible", JSON.stringify(tmpSaveObj));
    }
  }

  getSettingColumnVisibleLS(tableId: string) {
    const settingColumnVisibleObj = localStorage.getItem(
      "settingColumnVisible",
    );
    try {
      const tmpReturnArr = JSON.parse(settingColumnVisibleObj)[
        this.getUserName()
      ][tableId];

      if (tmpReturnArr === undefined) return [];
      return tmpReturnArr;
    } catch (e) {
      return [];
    }
    return [];
  }

  getTelemDimension(): string {
    const telemDimension = localStorage.getItem("telemDimension");
    return telemDimension === null ? "kpa" : telemDimension;
  }

  setTelemDimension(value: string) {
    localStorage.setItem("telemDimension", value);
  }

  saveAppVer() {
    localStorage.setItem("appVersion", appVersion);
  }
  getAppVer() {
    return localStorage.getItem("appVersion");
  }
}
